<template>
  <div>
    <v-container v-if="componentKey" class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="santralModel"
                    :items="santralDataList"
                    label="Santral"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ santralModel.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="startDate" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <!--   <v-flex xs2 sm4 md2 class="pr-3">
                    <v-dialog
                      ref="dialog"
                      v-model="hourmodal2"
                      :return-value.sync="hourmodel"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="hourmodel"
                          label="Saat"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="hourmodal2"
                        v-model="hourmodel"
                        full-width
                        :allowed-minutes="allowedStep"
                        format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="hourmodal2 = false">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(hourmodel)">
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-flex> -->

                <v-col cols="3" sm="1" md="1" class="pr-3">
                  <v-btn color="primary" @click="getData"> Filtrele </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="componentKey" class="flex-child mt-1 mr-1 ml-1">
        <template v-for="(item, k) in HeatmapData">
          {{ k }}

          <v-row :key="k" class="flex-child">
            <v-col
              v-for="n in item"
              :key="n.santral + n.INVERTER"
              class="d-flex"
            >
              <v-card
                class="ml-0 mr-0 pl-0 pr-0"
                width="100%"
                max-width="100px"
                min-width="60px"
                dark
                :color="setColor(n)"
              >
                <v-card-text class="">
                  <span
                    @click="inverterInfo(n)"
                    class="text-caption caption ml-0 mr-0 pl-0 pr-0"
                    >{{ n.INVERTER }}
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-divider :key="k" class="mt-1 mb-0 col-12" />
        </template>
      </v-row>

      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-text>
            <ul>
              <li>
                Manufacture :
                <span class="font-weight-bold">{{
                  inverterData.Manufacture
                }}</span>
              </li>
              <li>
                Model :
                <span class="font-weight-bold">{{ inverterData.Model }}</span>
              </li>

              <li>
                Seri Numara :
                <span class="font-weight-bold">{{ inverterData.Serial }}</span>
              </li>
              <li>
                Kurulu Güç :
                <span class="font-weight-bold">{{
                  inverterData.installedDcPower
                }}</span>
              </li>
              <li>
                Modül Sayısı :
                <span class="font-weight-bold">{{
                  inverterData.ModuleQuantity
                }}</span>
              </li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="dialog = false">
              Kapat
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import * as Heatmap from "@/api/Inverter/Heatmap";
import moment from "moment";
import i18n from "@/locale/index";
import Echart from "@/views/components/Echart/Chart.vue";

export default {
  name: "Dashboard",
  components: {
    Echart,
  },
  data: () => ({
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),
    inverterData: {},
    menu: false,
    modal: false,
    menu2: false,
    mina: "",
    maxa: "",
    dialog: false,
    nowDate: new Date().toISOString().slice(0, 10),

    menu1: false,
    menu2: false,
    minDate: "",
    menu: false,
    daterangetext: i18n.t("Tarih Seç"),
    selecteditemstext: "",
    mindate: "2010",
    all: i18n.t("All"),
    dateRules: [(v) => !!v || "Date is required"],
    dates: [],

    measureList: [],
    santral_items: [],

    minutesDailyEnergydate: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    measureList: [],
    measures: [],
    measureSelect: "ACPower",

    title: "",
    santralModel: [],
    inverterList: [],
    height: "",
    componentKey: 1,
    HeatmapData: {},
    temp: [],

    hourmenu: false,
    hourmodel: moment().format("HH:mm"),
    time: null,
    menu2: false,
    hourmodal2: false,
    dialog: false,
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      return santralDataList;
    },

    dateRangeText() {
      if (this.dates.length > 1) {
        const dateArr = this.dates;
        if (dateArr[0] > dateArr[1]) {
          this.selecteditemstext = `${dateArr[1]} ~ ${dateArr[0]}`;
        } else {
          this.selecteditemstext = `${dateArr[0]} ~ ${dateArr[1]}`;
        }
      } else {
        this.selecteditemstext = this.dates[0];
      }

      return this.selecteditemstext;
    },
    computedDateFormatted() {
      return this.formatDate();
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  watch: {},
  created() {
    this.hourmodel = moment().format("HH:mm");
  },
  mounted() {
    this.startDate = moment().format("YYYY-MM-DD");
    this.finishDate = moment().format("YYYY-MM-DD");
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    function myTimer1() {
      const StatsMeasure = self.$store.getters.santralInfo;
      console.log(StatsMeasure);

      if (Object.keys(StatsMeasure).length > 0) {
        self.santralInfo = StatsMeasure;

        self.getData();
        clearInterval(myVar1);
      }
    }
  },

  methods: {
    setColor(n) {
      //:color=" n.status == 1 && n.Error == 0 && n.Warning == 0 ? 'success' : 'red' "

      if (n.status == 1) {
        if (n.Error == 0 && n.Warning == 0) {
          return "success";
        }
        return "red";
      } else {
        return "grey";
      }
    },
    inverterInfo(item) {
      this.dialog = true;
      this.inverterData = item;
    },
    getData() {
      const formdata = {
        condiniton: {
          date: this.startDate,
          hour: this.hourmodel,
        },
      };

      if (this.santralModel.length > 0) {
        formdata.condiniton.santral = this.santralModel;
      }

      this.height = "800px";

      this.componentKey++;
      let HeatmapData = {};

      Object.keys(this.santralInfo).forEach((santral) => {
        if (!HeatmapData[santral]) {
          HeatmapData[santral] = {};
        }

        HeatmapData[santral] = this.santralInfo[santral].INVERTER;
      });

      this.HeatmapData = HeatmapData;

      const temp = Heatmap.default.heatmapStatus(formdata, this.santralInfo);
      temp.then((res) => {
        this.HeatmapData = res;
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },

    onDateSelected(daterange) {
      this.finishDate = moment(daterange[1]._d).format("YYYY-MM-DD");
      this.startDate = moment(daterange[0]._d).format("YYYY-MM-DD");
      const alrt = this.select_filter();

      if (alrt != undefined) {
        alert(alrt);
      }
    },
    cancelF() {
      this.menu = false;
      this.daterangetext = i18n.t("Date Range");
      this.$refs.menu.save(this.dates);
    },
    save(date) {
      this.daterangetext = `${i18n.t("Date Range")} ${i18n.t(
        this.selectcolumn
      )}`;
      this.$refs.menu.save(date);
      this.loading = true;
      const selectdate = this.dates;

      if (date.length < 2) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[0];
      } else if (selectdate[0] > selectdate[1]) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[1];
      } else {
        this.finishDate = selectdate[1];
        this.startDate = selectdate[0];
      }
    },
  },
};
</script>

<style scoped>
</style>
